import React from "react";
import Accordion from "./accordion_index";
import "../styles/accordion-style.css";
import PropTypes from "prop-types";
function acc(props) {
  return (
    <Accordion title={props.title} headerClass="accordion-index" className="accordion-index-container" isOpen={props.isOpen}>
      <div className="text-gary-500 font-normal text-md">{props.content}</div>
    </Accordion>
  );
}
export default acc;
acc.PropTypes = {
  title: PropTypes.string.isRequired, // must be a string and defined
  content: PropTypes.any.isRequired, // must be a string and defined
  isOpen:PropTypes.bool,
};
