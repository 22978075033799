import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
/* eslint react/prop-types: 0 */
function Migration(props) {
	return (
		<div className=" flex laptop:my-10 bg-white mobile:flex-col ">
			<div className="w-1/2 text-center  mt-10 mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
				{props.image}
			</div>

			<div className="w-1/2 m-auto mobile:text-center tablet:px-4 mobile:my-4 mobile:w-full tablet:w-full ">
				<h2 className=" font-extrabold  text-3xl mb-3 ">Easy Migration</h2>
				<p className="font-light  w-3/5 my-3 mobile:w-full mobile:pl-4 mobile:pr-8  tablet:w-full mobile:text-center">
					Fear no loss with Hostylus in data or ranking while migrating your
					website with recovery plans that prevent any imposter from sneak
					peeking at your data
				</p>
				<div className="w-2/3 mt-6 mobile:w-full">
					<Link
						to={"/wordpress-hosting"}
						className="outline-none border-black py-2 font-black uppercase rounded-3xl px-8 border-2 my-8 tablet:my-4 mobile:my-4"
					>
						Get started
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Migration;

Migration.propTypes = {
	image: PropTypes.object.isRequired, // must be a string and defined
};
