export default [
  {
    ID: "58",
    header: "Shared Hosting",
    desc: "A basic plan to fit your startup needs",
    btnLink: "/web-hosting",
    // price: 3.3,
    ulItems: [
      "100% SSD cloud hosting",
      "Unlimited bandwidth",
      "WordPress optimised",
      "Free SSL certificates",
      "Daily backups",
      "FREE website builder",
    ],
    Dell: "line-through",
    imgPath: "shared hosting Icon.svg",
    iconalt: "Hostylus shared hosting",
  },
  {
    ID: "3",
    header: "Wordpres Hosting",
    desc: "A basic plan to fit your startup needs",
    btnLink: "/wordpress-hosting",
    // price: 9.9,
    ulItems: [
      "Free Domain",
      "100% SSD cloud hosting",
      "Unlimited bandwidth",
      "WordPress optimised",
      "Free SSL certificates",
      "Daily backups",
      "FREE website builder",
    ],
    Dell: "",
    imgPath: "wordpress hosting Icon.svg",
    iconAlt: "Hostylus wordpress hosting",
  },

  {
    ID: "55",
    header: "Business Hosting",
    desc: "A basic plan to fit your startup needs",
    // price: 9.9,
    btnLink: "/business-hosting",
    ulItems: [
      "Free Domain",
      "100% SSD cloud hosting",
      "Unlimited bandwidth",
      "WordPress optimised",
      "Free SSL certificates",
      "Daily backups",
      "FREE website builder",
    ],
    Dell: " ",
    imgPath: "business hosting Icon.svg",
    iconAlt: "Hostylus Business Hosting",
  },

  {
    ID: "44",
    header: "Cloud VPS",
    desc: "A basic plan to fit your startup needs",
    // price: 5.99,
    btnLink: "/vps-hosting",
    ulItems: [
      "100% Pure NVMe SSD",
      "20TB Bandwidth",
      "Snapshots",
      "Backups",
      "DDOS Protection",
      "Floating IPs",
      "AMD EPYC 2nd Gen and Intel® Xeon® Gold processors",
    ],
    Dell: " ",
    imgPath: "Cloud VPN Icon.svg",
    iconAlt: "Hostylus cloud VPS",
  },
];
