export default [
  {
    isOpen:true,
    title: "Client’s Area Fully Optimized for Mobile Use ",
    content:
      "We’re aware that getting your tasks done is no longer desktop-restricted, that is why high responsiveness is something that we provide for all mobiles.You can get in touch with our support, get your payment done, ask for a new domain name or get advantage of other services, all; by tapping your phone screen only.",
  },
  {
    isOpen:false,
    title: "24/7 Support to Assist you",
    content:
      "You can get in touch with our support, get your payment done, ask for a new domain name or get advantage of other services, all; by tapping your phone screen only.",
  },

  {
    isOpen:false,
    title: "And Extra Features Awaiting you to Discover",
    content: "Deep analysis of the SSL web server configuration.",
  },
];
