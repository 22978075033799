import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
/* eslint react/prop-types: 0 */
function Blazing(props) {
  return (
    <div className=" flex m-auto laptop:my-10 bg-white mobile:my-10 mobile:flex-col-reverse ">
      <div className="w-1/2  my-auto mobile:my-4 mobile:text-center tablet:align-bottom mobile:w-full tablet:w-full ">
        <h2 className="  font-extrabold w-2/3 m-auto mobile:mt-5  text-3xl mb-3 tablet:w-full tablet:ml-9">
          Blazing Fast Hosting
        </h2>
        <p className="font-light  w-2/3 my-3 m-auto mobile:w-full mobile:px-10   mobile:text-center tablet:w-full tablet:ml-9">
          We assure you a hosting experience better than anything that you’ve
          ever had so why compromise for less when you could have more with us?
        </p>
        <div className="w-2/3 mt-6 mx-auto tablet:ml-9">
          <Link
            to={"/wordpress-hosting"}
            className="outline-none border-black py-2 font-black uppercase rounded-3xl px-8 border-2 my-8 tablet:my-4 mobile:my-4"
          >
            Get started
          </Link>
        </div>
      </div>
      <div className="w-1/2 mx-10 my-10 mobile:m-auto mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
        {props.image}
      </div>
    </div>
  );
}
export default Blazing;
Blazing.propTypes = {
  image: PropTypes.object.isRequired,
}; // must be a string and definedimport React from "react";
