import { Link } from "gatsby";
import React from "react";

const MoneyBack = () => {
  return (
    <div className="flex w-full h-48 bg-gradient-to-r from-blue-700 to-blue-600 px-24 py-8 tablet:p-6 mobile:p-6 mobile:block mobile:text-center mobile:h-60 laptop:px-12">
      <div className="w-1/2 my-auto mx-5 px-2 text-white laptop:w-2/3 tablet:w-2/3 tablet:mx-2 mobile:w-full mobile:m-0">
        <h2 className="text-3xl font-bold mobile:text-2xl">
          30-Day Money-Back Guarantee
        </h2>
        <p className="text-md font-light pr-24 mt-1 text-gray-50 laptop:pr-0 mobile:text-center mobile:px-5 mobile:mb-5 mobile:mt-3 mobile:text-sm">
          If you&apos;re not satisfied with our web hosting services, we&apos;ll refund your payment. No hassle, no risk.
        </p>
      </div>
      <div className="w-1/2 m-auto tablet:w-1/3 mobile:text-center mobile:w-full">
        <Link to={"/web-hosting"}>
          <button className="bg-white float-right px-14 text-black py-4 m-auto rounded-2xl hover:shadow-xl mobile:px-10 mobile:py-3 mobile:text-center mobile:float-none">
            Get Started
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MoneyBack;
