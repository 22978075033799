import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Migration from "../components/migration";
import Blazing from "../components/blazing";
import Landing from "../components/landingSection";
import PlanCard from "../components/plan_card";
import LI from "../components/plan-li";
import HomeDomainSearchInput from "../components/HomeDomainSearchInput";
import MoneyBack from "../components/MoneyBack";
import Packages from "../DB/plan-card-index";
import IndexSlides from "../DB/testmonials_slides";
import Reviews from "../components/reviews";
import Image from "../components/imageProcessing";
import { graphql, useStaticQuery } from "gatsby";
import Accordion from "../components/indexAccordion";
import AccordionContent from "../DB/Accordion-index";



function IndexPage() {

	const data = useStaticQuery(graphql`
		query indexQuery {
			allProducts(filter: { productID: { in: [58, 3, 55, 44] } }) {
				nodes {
					productID
					productGroupID
					annually
					monthly
				}
			}
		}
	`);
	// eslint-disable-next-line no-unused-vars

	return (
		<Layout>
			<SEO
				keywords={[
					`Hostylus`,
					`Web Hosting`,
					`Affordable Hosting`,
					`Reliable Hosting`,
					`Domain`,
					"Domains",
					"VPS",
					"WordPress Hosting",
					"hosting services",
					"cloud hosting",
					"shared hosting",
					"hosting plans",
					"SSD hosting",
					"free SSL",
					"customer support",
				]}
				title="Hostylus: Affordable & Reliable Web Hosting Services"
				lang="en"
				image={"/hostylus-card-preview.jpg"}
				description="Discover superb plans for WordPress hosting, shared hosting, VPS & more with Hostylus. Enjoy dazzling speed, free SSL, and a secure 1-click setup."
			/>

			<Landing
				smallHeader=""
				header={"Affordable and Reliable Web Hosting for Everyone"}
				desc="Experience superior web hosting with Hostylus. Why settle for less when you can have the best? Join us for a hosting experience like no other."
				btnURL="#pricing"
				btnText={"Get Started"}
				image={
					<Image
						image={"Image_With_Features_TzbgBf09ceLr.png"}
						alt={"Man holding his laptop, happy with Hostylus hosting service"}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>

			<section
				id="pricing"
				className="max-w-5xl mx-auto my-16 mobile:pb-3 tablet:mx-5"
			>
				<h2 className="text-4xl text-black font-bold text-center">
					Choose Your Best Hosting Plan
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3">
					High-Quality Plans at Fair Rates
				</h4>
				<div className="grid grid-cols-4 gap-2 flex justify-items-stretch mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:gab-2 mobile:justify-items-center laptop:px-8 py-8">
					{Packages.map(function (items, key) {
						let prices = 0;
						data.allProducts.nodes.forEach((product) => {
							if (items.ID == product.productID) {
								prices = product.monthly;
							}
						});
						return (
							<PlanCard
								key={key}
								price={prices}
								perWhat={"monthly"}
								planSVG={
									<img
										style={{ height: 10 + "rem" }}
										className="m-auto w-40 h-40"
										src={items.imgPath}
										alt={items.iconalt}
									/>
								}
								header={items.header}
								productID={items.btnLink}
								myItems={
									<ul
										style={{
											listStyleImage: "url('/tick.svg')",
											listStylePosition: "inside",
											justifySelf: "center",
										}}
										className="list-disc mobile:w-3/4"
									>
										{items.ulItems.map((listItem, listIndex) => (
											<LI key={listIndex} LI={listItem} textSize={"text-sm"} />
										))}
									</ul>
								}
							/>
						);   
					})}
				</div>
			</section>

			<section className="DomainChecker bg-blue-700">
				<div className="max-w-4xl mx-auto p-8 mobile:p-3">
					<h2 className="text-2xl text-center font-bold text-white">
						Find Your Perfect Domain Name
					</h2>
					<div id="domainSearch">
						<HomeDomainSearchInput
							attr="text-white"
							color="#fff"
						/>
					</div>
					<div id="results"></div>
				</div>
			</section>

			<Blazing
				image={
					<Image
						image={"blazing_zWUltm8vE.png"}
						alt={"Excited person raising hand to try Hostylus service"}
					/>
				}
			/>

			<Migration
				image={
					<Image
						image={"Happy man with hostylus migration.png"}
						alt={"Man satisfied with Hostylus free migration service"}
					/>
				}
			/>

			<section className="acc py-16 flex mobile:flex-col mobile:px-6 tablet:px-9 laptop:px-16 justify-items-center">
				<div className="py-16 px-32 laptop:pl-0 laptop:pr-5 laptop:py-0 mobile:px-0.5 tablet:px-1 w-1/2 mobile:w-full tablet:w-full laptop:w-full">
					<h2 className="font-extrabold mobile:text-center text-3xl capitalize">
						Enjoy a Simplified Experience
					</h2>
					<h6 className="text-md my-6 text-gray-500 mobile:text-center">
						Create and manage more websites with ease using our user-friendly control panel.
					</h6>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
							isOpen={items.isOpen}
						/>
					))}
				</div>
				<div className="w-1/2 tablet:py-16 mobile:w-full tablet:w-full laptop:w-full">
					<Image
						image={"clientarea.png"}
						alt={"Hostylus simple user dashboard"}
						classes={"shadow-3xl rounded-lg"}
					/>
				</div>
			</section>

			<Reviews
				slides={IndexSlides}
				heading="What Clients Say About Us"
				sub_heading="Clients Reviews"
			/>

			<MoneyBack />
		</Layout>
	);
}

export default IndexPage;
